import { config } from '../config.js';
import { toggleSpinner } from './toggleSpinner.js';
import { responseHandleForm } from './responseHandleForm.js';
import { Client, Databases, Query } from "appwrite";

//const endpoint = 'https://apiproto.ru/v1';
//const project = '65e0ba9e8cc7e97b05cb';
//const database = '65e0bf7048b8e4d3bad6';

//const endpoint = config.endpoint;
//const project = config.project;
//const database = config.database;

function setTimeStampQuery(input) {
    const encodedInput = btoa(encodeURIComponent(JSON.stringify(input)));
    const timeStamp = new Date().toISOString();
    sessionStorage.setItem(encodedInput, timeStamp);
}
function isTimeExpired(input, role) {
    const encodedInput = btoa(encodeURIComponent(JSON.stringify(input)));
    const storedTimeStamp = sessionStorage.getItem(encodedInput);

    if (!storedTimeStamp) {
        // Нет сохраненного значения
        return true;
    }

    // Переводим сохраненное время из ISO-строки в объект Date
    const storedDate = new Date(storedTimeStamp);

    const currentDate = new Date();

    const timeDifference = currentDate - storedDate;

    let valueTimeDifference = 30000; // 30 секунд (30000 миллисекунд)
    if (role == 'admin') {
        valueTimeDifference = 5000; // 5 секунд (5000 миллисекунд)
    }

    // Проверяем, больше ли разница чем valueTimeDifference
    return timeDifference > valueTimeDifference;
}

export let data = {
    local: [],
    session: []
};

let indexes = {
    queryIndex: new Map()
};

// Функция для выгрузки данных из хранилища
export function loadData(storageType) {
    if (storageType === 'local') {
        const localDataString = localStorage.getItem('data');
        if (localDataString) {
            data.local = JSON.parse(localDataString);
        } else {
            data.local = [];
        }
    } else if (storageType === 'session') {
        const sessionDataString = sessionStorage.getItem('data');
        if (sessionDataString) {
            data.session = JSON.parse(sessionDataString);
        } else {
            data.session = [];
        }
    } else {
        throw new Error('Invalid storageType. Use "local" or "session".');
    }
}

// Функция для сохранения данных в хранилище
function saveData() {
    localStorage.setItem('data', JSON.stringify(data.local));
    sessionStorage.setItem('data', JSON.stringify(data.session));
}

// Функция для добавления или обновления значения в заданном хранилище данных
export function addToStorage(storageType, doc) {
    let storage = (storageType === 'local') ? data.local : data.session;
    const index = storage.findIndex(d => d.$id === doc.$id && d.$collectionId === doc.$collectionId);
    if (index !== -1) {
        storage[index] = doc;
    } else {
        storage.push(doc);
    }
    saveData();
}

// Функция для удаления значения по ключу в определенном хранилище
function removeDataByKey(storageType, id, collectionId) {
    let storage = (storageType === 'local') ? data.local : data.session;
    const newStorage = storage.filter(d => !(d.$id === id && d.$collectionId === collectionId));
    if (storage.length !== newStorage.length) {
        if (storageType === 'local') {
            data.local = newStorage;
        } else {
            data.session = newStorage;
        }
        saveData();
    }
}

// Функция для фильтрации данных
export function getFilteredStorageData(storage, collectionId) {
    return storage.filter(doc => doc.$collectionId === collectionId && doc !== null);
}

// Функция для сохранения данных в индекс
function saveToIndex(input, data) {
    const indexKey = btoa(encodeURIComponent(JSON.stringify(input)));
    indexes.queryIndex.set(indexKey, data);
}

// Функция для удаления индекса
function removeIndex(input) {
    const indexKey = btoa(JSON.stringify(input));
    if (indexes.queryIndex.has(indexKey)) {
        indexes.queryIndex.delete(indexKey);
    }
}

// Функция для получения значений из индекса
function getIndexValues(input) {
    const indexKey = btoa(encodeURIComponent(JSON.stringify(input)));
    if (indexes.queryIndex.has(indexKey)) {
        return indexes.queryIndex.get(indexKey);
    }
    return null;
}

// Realtime Data
const subscriptions = new Map();
const render = new Map();
function subscribe(client, channel, storage, responseData) {
    if (subscriptions.has(channel)) {
        //console.log(`Удаление подписки на ${channel}`);
        const unsubscribe = subscriptions.get(channel);
        unsubscribe(); // Отписка от предыдущей подписки
        subscriptions.delete(channel);
    }
    
    const unsubscribe = client.subscribe(channel, (response) => {
        const doc = response.payload;
        const rerender = render.get(channel);

        if (response.events.some(event => event.endsWith('.create')) || response.events.some(event => event.endsWith('.update'))) {
            addToStorage(storage, doc);
            if (responseData) responseHandleForm(responseData);
            //console.log(`Добавлен: ${doc.$id}`);
        } else if (response.events.some(event => event.endsWith('.delete'))) {
            removeDataByKey(storage, doc.$id, doc.$collectionId);
            if (responseData) responseHandleForm(responseData);
            //console.log(`Удален: ${doc.$id}`);
        }
        rerender?.(true);
    });

    subscriptions.set(channel, unsubscribe); // Сохранить функцию отписки
    //console.log(`Подписка на ${channel} добавлена`);
}

export async function getData(input, rerender, rerendered) {
    const endpoint = config.endpoint;
    const project = config.project;
    const database = config.database;

    //console.log(input);

    if (!input.storage || (input.storage !== 'local' && input.storage !== 'session')) {
        input.storage = 'local';
    }

    //console.time('Data');
    if (input.storage === 'local' && data.local.length === 0) {
        loadData('local');
    } else if (input.storage === 'session' && data.session.length === 0) {
        loadData('session');
    }

    // Фильтрация ключей и сбор данных
    let storageData;
    if (input.storage === 'local') {
        storageData = getFilteredStorageData(data.local, input.collection);
    } else if (input.storage === 'session') {
        storageData = getFilteredStorageData(data.session, input.collection);
    }

    // Функция для общего сравнения значений
    const compareValues = (item, column, operator, value) => {
        if (operator === 'null') {
            return item[column] === null;
        } else if (operator === 'notNull') {
            return item[column] !== null;
        } else if (operator === 'search') {
            if (Array.isArray(column)) {
                return column.some(curr => item[curr] && item[curr].toLowerCase().includes(value.toLowerCase()));
            } else {
                return item[column] && item[column].toLowerCase().includes(value.toLowerCase());
            }
        }
        if (typeof value === 'boolean') {
            return operator === 'equal' ? (item[column] === value) : (item[column] !== value);
        } else if (isNaN(value)) {
            const dateValue = new Date(value);
            if (dateValue.toString() === "Invalid Date") {
                if (operator === 'equal') return String(item[column]) === value;
                else if (operator === 'notEqual') return String(item[column]) !== value;
                else if (operator === 'less') return String(item[column]) < value;
                else if (operator === 'lessEqual') return String(item[column]) <= value;
                else if (operator === 'greater') return String(item[column]) > value;
                else if (operator === 'greaterEqual') return String(item[column]) >= value;
            } else {
                const itemDateValue = new Date(item[column]).valueOf();
                if (operator === 'equal') return itemDateValue === dateValue.valueOf();
                else if (operator === 'notEqual') return itemDateValue !== dateValue.valueOf();
                else if (operator === 'less') return itemDateValue < dateValue.valueOf();
                else if (operator === 'lessEqual') return itemDateValue <= dateValue.valueOf();
                else if (operator === 'greater') return itemDateValue > dateValue.valueOf();
                else if (operator === 'greaterEqual') return itemDateValue >= dateValue.valueOf();
            }
        } else {
            const numericValue = parseFloat(value);
            if (operator === 'equal') return parseFloat(item[column]) === numericValue;
            else if (operator === 'notEqual') return parseFloat(item[column]) !== numericValue;
            else if (operator === 'less') return parseFloat(item[column]) < numericValue;
            else if (operator === 'lessEqual') return parseFloat(item[column]) <= numericValue;
            else if (operator === 'greater') return parseFloat(item[column]) > numericValue;
            else if (operator === 'greaterEqual') return parseFloat(item[column]) >= numericValue;
        }
        return false;
    }

    let filteredData = storageData;
    let index;
    if (isTimeExpired(input, input.role)) {
        index = getIndexValues(input);
    }
    if (index) {
        filteredData = index;
    } else if (filteredData.length > 0) {
        input.query.forEach((row) => {
            const { operator, value, column, from, to } = row;
            switch (operator) {
                case 'equal':
                case 'notEqual':
                case 'less':
                case 'lessEqual':
                case 'greater':
                case 'greaterEqual':
                    filteredData = filteredData.filter(item => compareValues(item, column, operator, value));
                    break;
                case 'between': // Между значениями
                    const dateFrom = new Date(from);
                    const dateTo = new Date(to);
                    if (isNaN(value)) {
                        if (dateFrom.toString() === "Invalid Date" || dateTo.toString() === "Invalid Date") {
                            filteredData = filteredData.filter(item => String(item[column]) >= from && String(item[column]) <= to);
                        } else {
                            filteredData = filteredData.filter(item => {
                                const itemDateValue = new Date(item[column]).valueOf();
                                return itemDateValue >= dateFrom.valueOf() && itemDateValue <= dateTo.valueOf();
                            });
                        }
                    } else {
                        filteredData = filteredData.filter(item => {
                            const itemNumericValue = parseFloat(item[column]);
                            return itemNumericValue >= parseFloat(from) && itemNumericValue <= parseFloat(to);
                        });
                    }
                    break;
                case 'null': // Значение null
                    filteredData = filteredData.filter(item => item[column] === null);
                    break;
                case 'notNull': // Значение не null
                    filteredData = filteredData.filter(item => item[column] !== null);
                    break;
                case 'search': // Поиск
                    filteredData = filteredData.filter(item => {
                        if (Array.isArray(column)) {
                            return column.some(curr => item[curr] && item[curr].toLowerCase().includes(value.toLowerCase()));
                        } else {
                            return item[column] && item[column].toLowerCase().includes(value.toLowerCase());
                        }
                    });
                    break;
            }
        });

        // Выполнение сортировки
        input.order.forEach((row) => {
            filteredData.sort((a, b) => {
                const { column, sorting } = row;
                if (isNaN(a[column]) || isNaN(b[column])) {
                    const dateA = new Date(a[column]);
                    const dateB = new Date(b[column]);
                    if (dateA.toString() !== "Invalid Date" && dateB.toString() !== "Invalid Date") {
                        return sorting === 'desc' ? dateB - dateA : dateA - dateB;
                    }
                    return sorting === 'desc' ? b[column].localeCompare(a[column]) : a[column].localeCompare(b[column]);
                }
                return sorting === 'desc' ? b[column] - a[column] : a[column] - b[column];
            });
        });

        if (input.role === 'user') {
            filteredData = filteredData.filter(item => item.public === true);
        }

        // Лимит и фильтр по ролевой модели доступны после основных операций
        /* if (input.limit) {
            filteredData = filteredData.slice(input.offset || 0, (input.offset || 0) + input.limit);
        } */
        if (input.limit && input.infinite) {
            //console.log(input.limit + (input.offset || 0));
            if (window.history?.state) {
                input.offset = window.history.state?.offset ? window.history.state.offset : input.offset;
            }

            filteredData = filteredData.slice(0, input.limit + (input.offset || 0));
        } else if (input.limit && !input.infinite) {
            filteredData = filteredData.slice(input.offset || 0, (input.offset || 0) + input.limit);
        }

        if (!input.infinite && !input.realtime) {
            saveToIndex(input, filteredData);
        }
    }

    //console.timeEnd('Data');
    if (navigator.onLine && rerendered !== true && isTimeExpired(input, input.role)) {
        const client = new Client()
            .setEndpoint(endpoint)
            .setProject(project)
            ;
        const databases = new Databases(client);

        //if ((filteredData.length < 1) || (filteredData.length % input.limit === 0 && input.limit > 0)) {
        //console.log(filteredData.length);
        if (filteredData.length < 1) {
            toggleSpinner(true);
        }

        let query = [];

        input.query.forEach((row) => {
            switch (row.operator) {
                case 'equal': // Равно
                    query.push(Query.equal(row.column, [row.value]));
                    break;
                case 'notEqual': // Не равно
                    query.push(Query.notEqual(row.column, [row.value]));
                    break;
                case 'less': // Меньше
                    query.push(Query.lessThan(row.column, [row.value]));
                    break;
                case 'lessEqual': // Меньше или равен
                    query.push(Query.lessThanEqual(row.column, [row.value]));
                    break;
                case 'greater': // Больше
                    query.push(Query.greaterThan(row.column, [row.value]));
                    break;
                case 'greaterEqual': // Больше или равен
                    query.push(Query.greaterThanEqual(row.column, [row.value]));
                    break;
                // Редкие кейсы
                case 'between': // Между значениями
                    query.push(Query.between(row.column, row.from, row.to));
                    break;
                case 'null': // Значение null
                    query.push(Query.isNull(row.column));
                    break;
                case 'notNull': // Значение не null
                    query.push(Query.isNotNull(row.column));
                    break;
                case 'search': // Поиск
                    query.push(Query.search(row.column, row.value));
                    break;
            }
        });
        input.order.forEach((row) => {
            switch (row.sorting) {
                case 'asc': // По возрастанию
                    query.push(Query.orderAsc(row.column));
                    break;
                case 'desc': // По уменьшению
                    query.push(Query.orderDesc(row.column));
                    break;
            }
        });
        if (input.limit) {
            query.push(Query.limit(input.limit));
        }
        if (input.offset) {
            // Если не число
            if (isNaN(input.offset)) {
                query.push(Query.cursorAfter(input.offset)); // как offset только по id последнего документа
            } else {
                query.push(Query.offset(input.offset));
            }
        }
        if (input.role == 'user') {
            query.push(Query.equal('public', [true]));
        };

        const promise = databases.listDocuments(
            database,
            input.collection,
            query
        );
        promise.then(function (response) {
            toggleSpinner(false);

            setTimeStampQuery(input);

            let updated = false;
            if (response.documents) {
                const filteredDataMap = new Map(filteredData.map(item => [item.$id, item]));
                const responseDocumentsMap = new Map(response.documents.map(doc => [doc.$id, doc]));

                if (input.infinite) {
                    filteredData = filteredData.slice(input.offset || 0, (input.offset || 0) + input.limit);
                }
                filteredData.forEach((item) => {
                    if (!responseDocumentsMap.has(item.$id)) {
                        removeDataByKey(input.storage, item.$id, item.$collectionId);
                        //removeIndex(input);
                        updated = true;
                    }
                });


                response.documents.forEach((doc) => {
                    const filteredItem = filteredDataMap.get(doc.$id);
                    if (!filteredItem || (filteredItem && filteredItem.$updatedAt !== doc.$updatedAt)) {
                        addToStorage(input.storage, doc);
                        //removeIndex(input);
                        updated = true;
                    }
                });

                if (!input.infinite && !input.realtime) {
                    saveToIndex(input, response.documents);
                }

                if (rerender && updated == true) {
                    rerender(true);
                };
            }
        }, function (error) {
            toggleSpinner(false);
            console.log(error); // Failure
        });
    } else {
        /* if (navigator.onLine) {
            console.log("Cache Data");
        } else {
            console.log("Offline mode");
        } */
    };
    if (navigator.onLine && input.realtime && rerendered !== true) {
        const channel = `databases.${database}.collections.${input.collection}.documents`;
        render.delete(channel);
        render.set(channel, rerender);
        if (!subscriptions.has(channel)) {
            const client = new Client()
                .setEndpoint(endpoint)
                .setProject(project)
            ;
            subscribe(client, channel, input.storage, input.response);
        }
    }
    return filteredData;
}