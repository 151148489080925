import { convertColor } from './convertColor.js';
import { convertGradient } from './convertGradient.js';
import { findColor } from './findColor.js';

export function parserCSS(input, rootContainerOrientation = 'row', theme = 'light') {
    function cleanArray(array) {
        return array.filter(item => item.trim() !== '');
    }

    const properties = [];

    if (input.textStyle) {
        properties.push(`font-family: ${input.textStyle.font.split('-')[0]};`);
        properties.push(`${input.textStyle.size ? `font-size: ${input.textStyle.size}px;` : 'font-size: 12px;'}`);
        if (input.textStyle.font.split('-')[1]) {
            properties.push(`font-weight: ${input.textStyle.font.split('-')[1] == 'Thin' ? '100' : ''}${input.textStyle.font.split('-')[1] == 'ExtraLight' ? '200' : ''}${input.textStyle.font.split('-')[1] == 'Light' ? '300' : ''}${input.textStyle.font.split('-')[1] == 'Regular' ? '400' : ''}${input.textStyle.font.split('-')[1] == 'Medium' ? '500' : ''}${input.textStyle.font.split('-')[1] == 'SemiBold' ? '600' : ''}${input.textStyle.font.split('-')[1] == 'ExtraBold' ? '800' : ''}${input.textStyle.font.split('-')[1] == 'Black' ? '900' : ''};`);
        }
        if (input.textStyle?.underline) {
            properties.push('text-decoration: underline;');
        } else {
            properties.push('text-decoration: none;');
        }
        if (input.textStyle.kerning) {
            properties.push(`letter-spacing: ${input.textStyle.kerning}px;`);
        }
        if (input.textStyle.lineHeight) {
            properties.push(`line-height: ${input.textStyle.lineHeight}px;`);
        } else {
            properties.push('line-height: 1.4375em;');
        }
        if (input.textStyle.align) {
            switch(input.textStyle.align) {
                case 1:
                    properties.push('text-align: end; justify-content: flex-end;');
                    break;
                case 2:
                    properties.push('text-align: center; justify-content: center;');
                    break;
                case 3:
                    properties.push('text-align: justify;');
                    break;
                default: properties.push('text-align: start;');
            }
        }
        if (input.textStyle?.color) {
            properties.push(`color: ${convertColor(input.textStyle.color)};`);
        } else if (input.textStyle?.colorId) {
            properties.push(`color: ${findColor(input.textStyle.colorId, theme)};`);
        } else {
            properties.push('color: #000000;');
        }
    }

    if (input?.link) {
        properties.push('cursor: pointer; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);');
    }

    properties.push('box-sizing: border-box;');

    if (input.hidden == true) {
        properties.push('display: none;');
    } else {
        properties.push('display: flex;');
    }

    if (input?.layoutFixPos && input.layoutFixPos == true) {
        properties.push('position: absolute;');
    }

    if (input.container) {
        if (input.container.padding) {
            properties.push(`padding: ${input.container.padding[1]}px ${input.container.padding[2]}px ${input.container.padding[3]}px ${input.container.padding[0]}px;`);
        }
        if (input.container.orientation == 1) {
            properties.push('flex-direction: column;');
            properties.push(`justify-content: ${input.container.valign == 1 ? 'center' : input.container.valign == 2 ? 'flex-end' : input.container.valign == 3 ? 'space-between' : 'flex-start'};`);
            properties.push(`align-items: ${input.container.align == 1 ? 'center' : input.container.align == 2 ? 'flex-end' : 'flex-start'};`);
        } else {
            properties.push(`justify-content: ${input.container.align == 1 ? 'center' : input.container.align == 2 ? 'flex-end' : input.container.align == 3 ? 'space-between' : 'flex-start'};`);
            properties.push(`align-items: ${input.container.valign == 1 ? 'center' : input.container.valign == 2 ? 'flex-end' : 'flex-start'};`);
            if (input.container.wrap) {
                properties.push('flex-wrap: wrap;');
                if (input.container.wrapDistribute == true) {
                    properties.push('align-content: space-between;');
                }
            }
        }
        if (input.container.wrapSpacing) {
            properties.push(`gap: ${input.container.wrapSpacing}px${input.container.spacing ? ` ${input.container.spacing}px` : ' 0px'};`);
        } else if (input.container.spacing) {
            properties.push(`gap: ${input.container.spacing}px;`);
        }
        
    }

    if (rootContainerOrientation === 'column') {
        if (input.layoutStretch) {
            properties.push('width: 100%;');
        } else if (input.container?.sizing && input.container.sizing === 1) {
            if (input.size) properties.push(`width: ${input.size[0]}px;`);
            if (input.overrides) {
                input.overrides.forEach(override => {
                    if (override.size) {
                        properties.push(`width: ${override.size[0]}px;`);
                    }
                });
            }
        } else if (input.behavior && input.size) {
            properties.push(`width: ${input.size[0]}px;`);
        }
    
        if (input.layoutGrowStretch) {
            properties.push('flex: 1 0 0; height: auto;');
        } else if (input.container?.vsizing && input.container.vsizing === 1) {
            if (input.size) properties.push(`height: ${input.size[1]}px;`);
            if (input.overrides) {
                input.overrides.forEach(override => {
                    if (override.size) {
                        properties.push(`height: ${override.size[1]}px;`);
                    }
                });
            }
        } else if (input.behavior === 2 && input.size) {
            properties.push(`height: ${input.size[1]}px;`);
        }
    } else if (rootContainerOrientation === 'wrap') {
        if (input.layoutStretch) {
            properties.push('align-self: stretch;');
        } else if (input.container?.sizing && input.container.sizing === 1) {
            if (input.size) properties.push(`width: ${input.size[0]}px;`);
            if (input.overrides) {
                input.overrides.forEach(override => {
                    if (override.size) {
                        properties.push(`width: ${override.size[0]}px;`);
                    }
                });
            }
        } else if (input.behavior && input.size) {
            properties.push(`width: ${input.size[0]}px;`);
        }
    
        if (input.layoutGrowStretch) {
            properties.push('flex: 1 0 0;');
        } else if (input.container?.vsizing && input.container.vsizing === 1) {
            if (input.size) properties.push(`height: ${input.size[1]}px;`);
            if (input.overrides) {
                input.overrides.forEach(override => {
                    if (override.size) {
                        properties.push(`height: ${override.size[1]}px;`);
                    }
                });
            }
        } else if (input.behavior === 2 && input.size) {
            properties.push(`height: ${input.size[1]}px;`);
        }
    } else if (rootContainerOrientation === 'row') {
        if (input.layoutStretch) {
            properties.push('align-self: stretch;');
        } else if (input.container?.vsizing && input.container.vsizing === 1) {
            if (input.size) properties.push(`height: ${input.size[1]}px;`);
            if (input.overrides) {
                input.overrides.forEach(override => {
                    if (override.size) {
                        properties.push(`height: ${override.size[1]}px;`);
                    }
                });
            }
        } else if (input.behavior === 2 && input.size) {
            properties.push(`height: ${input.size[1]}px;`);
        }
    
        if (input.layoutGrowStretch) {
            properties.push('flex: 1 0 0; width: auto;');
        } else if (input.container?.sizing && input.container.sizing === 1) {
            if (input.size) properties.push(`width: ${input.size[0]}px;`);
            if (input.overrides) {
                input.overrides.forEach(override => {
                    if (override.size) {
                        properties.push(`width: ${override.size[0]}px;`);
                    }
                });
            }
        } else if (input.behavior && input.size) {
            properties.push(`width: ${input.size[0]}px;`);
        }
    }

    if ((input._t !== 'INSTANCE' && input?.clipContent === undefined) || input.clipContent === true) {
        properties.push('overflow: hidden;');
    }

    if (input.opacity) {
        properties.push(`opacity: ${input.opacity};`);
    }

    if (input._t !== 'TEXT' && !input.container && input.size) {
        properties.push(`display: block; width: ${input.size[0]}px; height: ${input.size[1]}px;`);
    }
    
    if (input.minWidth) {
        properties.push(`min-width: ${input.minWidth}px;`);
    }
    
    if (input.maxWidth) {
        properties.push(`max-width: ${input.maxWidth}px;`);
    }
    
    if (input.minHeight) {
        properties.push(`min-height: ${input.minHeight}px;`);
    }
    
    if (input.maxHeight) {
        properties.push(`max-height: ${input.maxHeight}px;`);
    }
    
    if (input.size && input.lockAspect === true) {
        if (input.layoutStretch) {
            properties.push('height: auto;');
        } else if (input.layoutGrowStretch) {
            properties.push('width: auto;');
        }
        properties.push(`aspect-ratio: ${input.size[0]} / ${input.size[1]}; height: auto;`);
    }

    if (input.fills) {
        input.fills.forEach(fill => {
            if (fill?.type && fill.type == 4) {
                if (fill?.color && fill.color !== '80') {
                    properties.push(`background-color: ${convertColor(fill.color)};`);
                }
                if (fill.gradient) {
                    properties.push(`background-image: ${convertGradient(fill.gradient)};`);
                }
                if (fill?.pattern && fill.pattern?.type) {
                    if (fill.pattern.type == 1) {
                        properties.push('background-size: cover; object-fit: cover;');
                    } else if (fill.pattern.type == 2) {
                        properties.push('background-size: fill; object-fit: fill;');
                    } else if (fill.pattern.type == 3) {
                        properties.push('background-size: contain; object-fit: contain;');
                    }
                }
            } else {
                if (fill.color) {
                    properties.push(`background-color: ${convertColor(fill.color)};`);
                }
                if (fill.colorId) {
                    properties.push(`background-color: ${findColor(fill.colorId, theme)};`);
                }
                if (fill.gradient) {
                    properties.push(`background-image: ${convertGradient(fill.gradient)};`);
                }
            }
        });
    }

    if (input.borders || input.innerShadows || input.shadows) {
        const shadowProperties = [];
    
        if (input.borders) {
            input.borders.reverse().forEach(border => {
                if (border.pos) {
                    if (border.pos == 1) {
                        if (border.thickness) {
                            shadowProperties.push(`0px 0px 0px ${border.thickness}px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        } else if (border.customThickness) {
                            shadowProperties.push(`-${border.customThickness[2]}px ${border.customThickness[1]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                            shadowProperties.push(`${border.customThickness[0]}px -${border.customThickness[3]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                            shadowProperties.push(`-${border.customThickness[2]}px -${border.customThickness[3]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                            shadowProperties.push(`${border.customThickness[0]}px ${border.customThickness[1]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        } else {
                            shadowProperties.push(`0px 0px 0px 1px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        }
                    } else if (border.pos == 2) {
                        if (border.thickness) {
                            shadowProperties.push(`0px 0px 0px ${border.thickness}px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                        } else if (border.customThickness) {
                            shadowProperties.push(`${border.customThickness[2]}px -${border.customThickness[1]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                            shadowProperties.push(`-${border.customThickness[0]}px ${border.customThickness[3]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                            shadowProperties.push(`${border.customThickness[2]}px ${border.customThickness[3]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                            shadowProperties.push(`-${border.customThickness[0]}px -${border.customThickness[1]}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                        } else {
                            shadowProperties.push(`0px 0px 0px 1px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                        }
                    }
                } else {
                    if (border.thickness) {
                        shadowProperties.push(`0px 0px 0px ${border.thickness / 2}px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        shadowProperties.push(`0px 0px 0px ${border.thickness / 2}px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                    } else if (border.customThickness) {
                        shadowProperties.push(`-${border.customThickness[2] / 2}px ${border.customThickness[1] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        shadowProperties.push(`${border.customThickness[0] / 2}px -${border.customThickness[3] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        shadowProperties.push(`-${border.customThickness[2] / 2}px -${border.customThickness[3] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        shadowProperties.push(`${border.customThickness[0] / 2}px ${border.customThickness[1] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        shadowProperties.push(`${border.customThickness[2] / 2}px -${border.customThickness[1] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                        shadowProperties.push(`-${border.customThickness[0] / 2}px ${border.customThickness[3] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                        shadowProperties.push(`${border.customThickness[2] / 2}px ${border.customThickness[3] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                        shadowProperties.push(`-${border.customThickness[0] / 2}px -${border.customThickness[1] / 2}px 0px 0px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                    } else {
                        shadowProperties.push(`0px 0px 0px 0.5px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'} inset`);
                        shadowProperties.push(`0px 0px 0px 0.5px ${border?.color ? convertColor(border.color) : border?.colorId ? findColor(border.colorId, theme) : '#000'}`);
                    }
                }
            });
        }
    
        if (input.innerShadows) {
            input.innerShadows.reverse().forEach(shadow => {
                shadowProperties.push(
                    `${shadow.offset ? shadow.offset[0] : 0}px ` +
                    `${shadow.offset ? shadow.offset[1] : 0}px ` +
                    `${shadow.radius ? shadow.radius : 0}px ` +
                    `${shadow.spread ? shadow.spread : 0}px ` +
                    `${convertColor(shadow.color)} inset`
                );
            });
        }
    
        if (input.shadows) {
            input.shadows.reverse().forEach(shadow => {
                shadowProperties.push(
                    `${shadow.offset ? shadow.offset[0] : 0}px ` +
                    `${shadow.offset ? shadow.offset[1] : 0}px ` +
                    `${shadow.radius ? shadow.radius : 0}px ` +
                    `${shadow.spread ? shadow.spread : 0}px ` +
                    `${convertColor(shadow.color)}`
                );
            });
        }
    
        if (shadowProperties.length > 0) {
            properties.push(`box-shadow: ${shadowProperties.join(', ')};`);
        }
    }

    if (input.cornerRadius) {
        properties.push(`border-radius: ${input.cornerRadius[0]}px ${input.cornerRadius[1]}px ${input.cornerRadius[2]}px ${input.cornerRadius[3]}px;`);
    }

    if (input._t == 'INSTANCE') {
        if (input.overrides) {
            input.overrides.forEach(override => {
                if (override.fills) {
                    override.fills.forEach(fill => {
                        if (fill.color) {
                            properties.push(`fill: ${convertColor(fill.color)};`);
                        } else if (fill.colorId) {
                            properties.push(`fill: ${findColor(fill.colorId, theme)};`);
                        }
                    });
                }
                if (override.cornerRadius) {
                    properties.push(`border-radius: ${override.cornerRadius[0]}px ${override.cornerRadius[1]}px ${override.cornerRadius[2]}px ${override.cornerRadius[3]}px;`);
                }
            });
        }
    }
        

    //console.log(cleanArray(properties).join(' '));

    return cleanArray(properties).join(' ');
}