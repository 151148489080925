import {config} from '../config.js';
import { changeURL } from './changeURL.js';
import { parserCSS } from './parserCSS.js';
import { createCSS } from './createCSS.js';
import { createHTML, parseIfString } from './createHTML.js';
import { getData } from './getData.js';
import { getAccount } from './getAccount.js';
import { postData } from './postData.js';
import { uploadImage } from './uploadImage.js';
import { querySelectorAllDeep } from './querySelectorAllDeep.js';

//import { qrScanner } from './qrScanner.js';
import { BrowserMultiFormatReader } from '@zxing/library';

import { LitElement, html, render } from 'lit';
import { until } from 'lit/directives/until.js';

import { design } from '../app.js';

function replaceTargets(defaultComponent, selectedComponent, overrides) {
    if (!Array.isArray(overrides)) {
        return overrides;
    }

    const defaultLayers = defaultComponent.layers;
    const selectedLayers = selectedComponent.layers;

    if (!defaultLayers || !selectedLayers) {
        console.error("Layers are missing in the component data");
        return overrides;
    }

    // Функция для создания мапы слоев
    function createLayerMap(layers) {
        const map = {};
        for (const layer of layers) {
            map[layer.name] = layer.id;
            if (layer.layers) {
                Object.assign(map, createLayerMap(layer.layers));
            }
        }
        return map;
    }

    // Создаем карты ID для default и selected компонент
    const defaultMap = createLayerMap(defaultLayers);
    const selectedMap = createLayerMap(selectedLayers);

    // Проходим по overrides и меняем целевые ID
    for (const override of overrides) {
        if (override.target && override.target.length === 1) {
            const targetId = override.target[0];
            for (const [name, id] of Object.entries(selectedMap)) {
                if (id === targetId) {
                    override.target[0] = defaultMap[name];
                    break;
                }
            }
        }
    }

    return overrides;
}

export function createComponent(componentName) {
    let component = design.filter(component =>
        component._t === "COMPONENT" && componentName === component.name.split('/')[0]
    );

    if (component) {
        let className = 'Component_' + componentName;

        window[className] = class extends LitElement {
            constructor() {
                super();
                this.needRender = false;
                this.attachShadow({ mode: 'open' });
                this.handleInputChange = this.handleInputChange.bind(this);

                this.clickCount = 0;
                
                this.codeReader = '';
                this.videoElement = '';
                this.qrScanning = true;

                this.handleIntersection = this.handleIntersection.bind(this);
            }

            async connectedCallback() {
                if (this.shadowRoot.clickHandler) {
                    this.shadowRoot.removeEventListener('pointerup', this.shadowRoot.clickHandler);
                }
                this.shadowRoot.clickHandler = this.clickHandler;
                this.shadowRoot.addEventListener('pointerup', this.shadowRoot.clickHandler);

                // Этот метод будет вызван, когда компонент добавляется в DOM
                await this.handleFormSubmission();
                
            }

            disconnectedCallback() {
                super.disconnectedCallback();

                this.shadowRoot.clickHandler = this.clickHandler;
                this.shadowRoot.removeEventListener('pointerup', this.shadowRoot.clickHandler);

                if (this.codeReader && this.videoElement) {
                    this.stopQRScanner();
                }

                if (this.observer) {
                    this.observer.disconnect(); // Отключаем наблюдатель при удалении компонента
                }
            }

            static get observedAttributes() {
                return ['overrides', 'data', 'clickevent', 'props', 'setprops', 'disabled', 'selected', 'error'];
            }

            attributeChangedCallback(name, oldValue, newValue) {
                const hasChanged = oldValue !== newValue;
                if (hasChanged) {
                    if (name === 'data') {
                        try {
                            this.data = JSON.parse(decodeURIComponent(atob(newValue)));
                        } catch (error) {
                            console.error('Ошибка при разборе data:', error);
                        }
                    } else if (name === 'overrides') {
                        try {
                            this.overrides = JSON.parse(decodeURIComponent(atob(newValue)));
                        } catch (error) {
                            console.error('Ошибка при разборе overrides:', error);
                        }
                    } else if (name === 'props') {
                        try {
                            this.props = JSON.parse(decodeURIComponent(atob(newValue)));
                        } catch (error) {
                            console.error('Ошибка при разборе props:', error);
                        }
                    } else {
                        this[name] = newValue;
                    }
                    this.clickCount = 0;
                    this.requestRender();
                }
            }

            requestRender() {
                if (!this.needRender) {
                    this.needRender = true;
                    Promise.resolve().then(() => {
                        if (this.needRender) {
                            this.render(false);
                            this.handleInputChange();
                            this.needRender = false;
                        }
                    });
                }
            }

            render = (updated) => {
                let props = {};
                if (this.props) {
                    props = this.props;
                }

                if (this.hasAttribute('error')) {
                    const errorMessage = this.getAttribute('error');
                    if (props.error !== errorMessage) {
                        props.error = errorMessage;
                        this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                    }
                }

                if (props.form && props.state === 'submit') {
                    this.handleFormSubmission(props.id, props.form, props.success ? props.success : '', props.error ? props.error : '');
                }
                if (props.file === 'select') {
                    const input = this.shadowRoot.querySelector('.input-image');
                    input.click();
                    let setProps = { 'file': '' };
                    Object.assign(props, setProps);
                    let updatedProps = JSON.stringify(props);
                    this.setAttribute('props', btoa(encodeURIComponent(updatedProps)));
                }

                let stateEnabled = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'enabled';
                });
                let stateHovered = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'hovered';
                });
                let statePressed = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'pressed';
                });
                let stateFocused = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'focused';
                });
                let stateDisabled = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'disabled';
                });

                let stateEnabledSelected = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'selected:enabled';
                });
                let stateHoveredSelected = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'selected:hovered';
                });
                let statePressedSelected = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'selected:pressed';
                });
                let stateFocusedSelected = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'selected:focused';
                });
                let stateDisabledSelected = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'selected:disabled';
                });

                let stateEnabledError = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'error:enabled';
                });
                let stateHoveredError = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'error:hovered';
                });
                let statePressedError = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'error:pressed';
                });
                let stateFocusedError = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'error:focused';
                });
                let stateDisabledError = component.find(component => {
                    let nameParts = component.name.split('/');
                    return nameParts[0] === componentName && nameParts[1] === 'error:disabled';
                });

                if (this.hasAttribute('selected')) {
                    this.overrides = replaceTargets(stateEnabled, stateEnabledSelected, this.overrides);
                };

                const template = html`
                    <style>
                        ${stateEnabled ? `
                            :host {
                                ${parserCSS(stateEnabled)}${stateEnabled?.layoutFixPos && stateEnabled.layoutFixPos == true ? '' : 'position: relative;'}
                            }
                            ${stateEnabled.blur && stateEnabled.blur.type === 3 ? `
                                :host::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    backdrop-filter: blur(${stateEnabled.blur.radius}px);
                                    -webkit-backdrop-filter: blur(${stateEnabled.blur.radius}px);
                                    ${stateEnabled?.fills ? '' : 'background: rgba(255, 255, 255, 0.1);'}
                                    z-index: 0;
                                }
                            ` : ''}
                            ${Array.isArray(stateEnabled.layers) ? `
                                ${stateEnabled.layers.map(layer => createCSS(layer, `${stateEnabled.container ? `${stateEnabled.container.orientation == 1 ? 'column' : stateEnabled.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host')).join('')}
                            ` : ''}
                        ` : ''}${stateHovered ? `
                            @media (hover: hover) and (pointer: fine) {
                                :host(:hover) {
                                    ${parserCSS(stateHovered)}
                                }
                                ${Array.isArray(stateHovered.layers) ? `
                                    ${stateHovered.layers.map(layer => createCSS(layer, `${stateHovered.container ? `${stateHovered.container.orientation == 1 ? 'column' : stateHovered.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:hover)')).join('')}
                                ` : ''}
                            }
                        ` : ''}${statePressed ? `
                            :host(:active) {
                                ${parserCSS(statePressed)}
                            }
                            ${Array.isArray(statePressed.layers) ? `
                                ${statePressed.layers.map(layer => createCSS(layer, `${statePressed.container ? `${statePressed.container.orientation == 1 ? 'column' : statePressed.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:active)')).join('')}
                            ` : ''}
                        ` : ''}${stateFocused ? `
                            :host(:focus), :host(:focus-within) {
                                ${parserCSS(stateFocused)}
                            }
                            ${Array.isArray(stateFocused.layers) ? `
                                ${stateFocused.layers.map(layer => createCSS(layer, `${stateFocused.container ? `${stateFocused.container.orientation == 1 ? 'column' : stateFocused.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:focus)')).join('')}
                            ` : ''}
                            ${Array.isArray(stateFocused.layers) ? `
                                ${stateFocused.layers.map(layer => createCSS(layer, `${stateFocused.container ? `${stateFocused.container.orientation == 1 ? 'column' : stateFocused.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:focus-within)')).join('')}
                            ` : ''}
                        ` : ''}${stateDisabled ? `
                            :host([disabled]), :host([disabled]:hover), :host([disabled]:active), :host([disabled]:focus), :host([disabled]:focus-within) {
                                ${parserCSS(stateDisabled)}
                                cursor: default;
                            }
                            ${Array.isArray(stateDisabled.layers) ? `
                                ${stateDisabled.layers.map(layer => createCSS(layer, `${stateDisabled.container ? `${stateDisabled.container.orientation == 1 ? 'column' : stateDisabled.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([disabled])')).join('')}
                            ` : ''}
                        ` : ''}${stateEnabledSelected ? `
                            :host([selected]) {
                                ${parserCSS(stateEnabledSelected)}
                            }
                            ${Array.isArray(stateEnabledSelected.layers) ? `
                                ${stateEnabledSelected.layers.map(layer => createCSS(layer, `${stateEnabledSelected.container ? `${stateEnabledSelected.container.orientation == 1 ? 'column' : stateEnabledSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected])')).join('')}
                            ` : ''}
                        ` : ''}${stateHoveredSelected ? `
                            @media (hover: hover) and (pointer: fine) {
                                :host([selected]:hover) {
                                    ${parserCSS(stateHoveredSelected)}
                                }
                                ${Array.isArray(stateHoveredSelected.layers) ? `
                                    ${stateHoveredSelected.layers.map(layer => createCSS(layer, `${stateHoveredSelected.container ? `${stateHoveredSelected.container.orientation == 1 ? 'column' : stateHoveredSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:hover)')).join('')}
                                ` : ''}
                            }
                        ` : ''}${statePressedSelected ? `
                            :host([selected]:active) {
                                ${parserCSS(statePressedSelected)}
                            }
                            ${Array.isArray(statePressedSelected.layers) ? `
                                ${statePressedSelected.layers.map(layer => createCSS(layer, `${statePressedSelected.container ? `${statePressedSelected.container.orientation == 1 ? 'column' : statePressedSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:active)')).join('')}
                            ` : ''}
                        ` : ''}${stateFocusedSelected ? `
                            :host([selected]:focus), :host([selected]:focus-within) {
                                ${parserCSS(stateFocusedSelected)}
                            }
                            ${Array.isArray(stateFocusedSelected.layers) ? `
                                ${stateFocusedSelected.layers.map(layer => createCSS(layer, `${stateFocusedSelected.container ? `${stateFocusedSelected.container.orientation == 1 ? 'column' : stateFocusedSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:focus)')).join('')}
                            ` : ''}
                            ${Array.isArray(stateFocusedSelected.layers) ? `
                                ${stateFocusedSelected.layers.map(layer => createCSS(layer, `${stateFocusedSelected.container ? `${stateFocusedSelected.container.orientation == 1 ? 'column' : stateFocusedSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:focus-within)')).join('')}
                            ` : ''}
                        ` : ''}${stateDisabledSelected ? `
                            :host([selected][disabled]), :host([selected][disabled]:hover), :host([selected][disabled]:active), :host([selected][disabled]:focus), :host([selected][disabled]:focus-within) {
                                ${parserCSS(stateDisabledSelected)}
                                cursor: default;
                            }
                            ${Array.isArray(stateDisabledSelected.layers) ? `
                                ${stateDisabledSelected.layers.map(layer => createCSS(layer, `${stateDisabledSelected.container ? `${stateDisabledSelected.container.orientation == 1 ? 'column' : stateDisabledSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected][disabled])')).join('')}
                            ` : ''}
                        ` : ''}${stateEnabledError ? `
                            :host([error]) {
                                ${parserCSS(stateEnabledError)}
                            }
                            ${Array.isArray(stateEnabledError.layers) ? `
                                ${stateEnabledError.layers.map(layer => createCSS(layer, `${stateEnabledError.container ? `${stateEnabledError.container.orientation == 1 ? 'column' : stateEnabledError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error])')).join('')}
                            ` : ''}
                        ` : ''}${stateHoveredError ? `
                            @media (hover: hover) and (pointer: fine) {
                                :host([error]:hover) {
                                    ${parserCSS(stateHoveredError)}
                                }
                                ${Array.isArray(stateHoveredError.layers) ? `
                                    ${stateHoveredError.layers.map(layer => createCSS(layer, `${stateHoveredError.container ? `${stateHoveredError.container.orientation == 1 ? 'column' : stateHoveredError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:hover)')).join('')}
                                ` : ''}
                            }
                        ` : ''}${statePressedError ? `
                            :host([error]:active) {
                                ${parserCSS(statePressedError)}
                            }
                            ${Array.isArray(statePressedError.layers) ? `
                                ${statePressedError.layers.map(layer => createCSS(layer, `${statePressedError.container ? `${statePressedError.container.orientation == 1 ? 'column' : statePressedError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:active)')).join('')}
                            ` : ''}
                        ` : ''}${stateFocusedError ? `
                            :host([error]:focus), :host([error]:focus-within) {
                                ${parserCSS(stateFocusedError)}
                            }
                            ${Array.isArray(stateFocusedError.layers) ? `
                                ${stateFocusedError.layers.map(layer => createCSS(layer, `${stateFocusedError.container ? `${stateFocusedError.container.orientation == 1 ? 'column' : stateFocusedError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:focus)')).join('')}
                            ` : ''}
                            ${Array.isArray(stateFocusedError.layers) ? `
                                ${stateFocusedError.layers.map(layer => createCSS(layer, `${stateFocusedError.container ? `${stateFocusedError.container.orientation == 1 ? 'column' : stateFocusedError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:focus-within)')).join('')}
                            ` : ''}
                        ` : ''}${stateDisabledError ? `
                            :host([error][disabled]), :host([error][disabled]:hover), :host([error][disabled]:active), :host([error][disabled]:focus), :host([error][disabled]:focus-within) {
                                ${parserCSS(stateDisabledError)}
                                cursor: default;
                            }
                            ${Array.isArray(stateDisabledError.layers) ? `
                                ${stateDisabledError.layers.map(layer => createCSS(layer, `${stateDisabledError.container ? `${stateDisabledError.container.orientation == 1 ? 'column' : stateDisabledError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error][disabled])')).join('')}
                            ` : ''}
                        ` : ''}
                        ${config.darkTheme ? `
                            @media (prefers-color-scheme: dark) {
                                ${stateEnabled ? `
                                    :host {
                                        ${parserCSS(stateEnabled, '', 'dark')}${stateEnabled?.layoutFixPos && stateEnabled.layoutFixPos == true ? '' : 'position: relative;'}
                                    }
                                    ${stateEnabled.blur && stateEnabled.blur.type === 3 ? `
                                        :host::before {
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            backdrop-filter: blur(${stateEnabled.blur.radius}px);
                                            -webkit-backdrop-filter: blur(${stateEnabled.blur.radius}px);
                                            ${stateEnabled?.fills ? '' : 'background: rgba(255, 255, 255, 0.1);'}
                                            z-index: 0;
                                        }
                                    ` : ''}
                                    ${Array.isArray(stateEnabled.layers) ? `
                                        ${stateEnabled.layers.map(layer => createCSS(layer, `${stateEnabled.container ? `${stateEnabled.container.orientation == 1 ? 'column' : stateEnabled.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateHovered ? `
                                    @media (hover: hover) and (pointer: fine) {
                                        :host(:hover) {
                                            ${parserCSS(stateHovered, '', 'dark')}
                                        }
                                        ${Array.isArray(stateHovered.layers) ? `
                                            ${stateHovered.layers.map(layer => createCSS(layer, `${stateHovered.container ? `${stateHovered.container.orientation == 1 ? 'column' : stateHovered.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:hover)', 'dark')).join('')}
                                        ` : ''}
                                    }
                                ` : ''}${statePressed ? `
                                    :host(:active) {
                                        ${parserCSS(statePressed, '', 'dark')}
                                    }
                                    ${Array.isArray(statePressed.layers) ? `
                                        ${statePressed.layers.map(layer => createCSS(layer, `${statePressed.container ? `${statePressed.container.orientation == 1 ? 'column' : statePressed.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:active)', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateFocused ? `
                                    :host(:focus), :host(:focus-within) {
                                        ${parserCSS(stateFocused, '', 'dark')}
                                    }
                                    ${Array.isArray(stateFocused.layers) ? `
                                        ${stateFocused.layers.map(layer => createCSS(layer, `${stateFocused.container ? `${stateFocused.container.orientation == 1 ? 'column' : stateFocused.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:focus)', 'dark')).join('')}
                                    ` : ''}
                                    ${Array.isArray(stateFocused.layers) ? `
                                        ${stateFocused.layers.map(layer => createCSS(layer, `${stateFocused.container ? `${stateFocused.container.orientation == 1 ? 'column' : stateFocused.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host(:focus-within)', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateDisabled ? `
                                    :host([disabled]), :host([disabled]:hover), :host([disabled]:active), :host([disabled]:focus), :host([disabled]:focus-within) {
                                        ${parserCSS(stateDisabled, '', 'dark')}
                                        cursor: default;
                                    }
                                    ${Array.isArray(stateDisabled.layers) ? `
                                        ${stateDisabled.layers.map(layer => createCSS(layer, `${stateDisabled.container ? `${stateDisabled.container.orientation == 1 ? 'column' : stateDisabled.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([disabled])', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateEnabledSelected ? `
                                    :host([selected]) {
                                        ${parserCSS(stateEnabledSelected, '', 'dark')}
                                    }
                                    ${Array.isArray(stateEnabledSelected.layers) ? `
                                        ${stateEnabledSelected.layers.map(layer => createCSS(layer, `${stateEnabledSelected.container ? `${stateEnabledSelected.container.orientation == 1 ? 'column' : stateEnabledSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected])', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateHoveredSelected ? `
                                    @media (hover: hover) and (pointer: fine) {
                                        :host([selected]:hover) {
                                            ${parserCSS(stateHoveredSelected, '', 'dark')}
                                        }
                                        ${Array.isArray(stateHoveredSelected.layers) ? `
                                            ${stateHoveredSelected.layers.map(layer => createCSS(layer, `${stateHoveredSelected.container ? `${stateHoveredSelected.container.orientation == 1 ? 'column' : stateHoveredSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:hover)', 'dark')).join('')}
                                        ` : ''}
                                    }
                                ` : ''}${statePressedSelected ? `
                                    :host([selected]:active) {
                                        ${parserCSS(statePressedSelected, '', 'dark')}
                                    }
                                    ${Array.isArray(statePressedSelected.layers) ? `
                                        ${statePressedSelected.layers.map(layer => createCSS(layer, `${statePressedSelected.container ? `${statePressedSelected.container.orientation == 1 ? 'column' : statePressedSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:active)', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateFocusedSelected ? `
                                    :host([selected]:focus), :host([selected]:focus-within) {
                                        ${parserCSS(stateFocusedSelected, '', 'dark')}
                                    }
                                    ${Array.isArray(stateFocusedSelected.layers) ? `
                                        ${stateFocusedSelected.layers.map(layer => createCSS(layer, `${stateFocusedSelected.container ? `${stateFocusedSelected.container.orientation == 1 ? 'column' : stateFocusedSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:focus)', 'dark')).join('')}
                                    ` : ''}
                                    ${Array.isArray(stateFocusedSelected.layers) ? `
                                        ${stateFocusedSelected.layers.map(layer => createCSS(layer, `${stateFocusedSelected.container ? `${stateFocusedSelected.container.orientation == 1 ? 'column' : stateFocusedSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected]:focus-within)', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateDisabledSelected ? `
                                    :host([selected][disabled]), :host([selected][disabled]:hover), :host([selected][disabled]:active), :host([selected][disabled]:focus), :host([selected][disabled]:focus-within) {
                                        ${parserCSS(stateDisabledSelected, '', 'dark')}
                                        cursor: default;
                                    }
                                    ${Array.isArray(stateDisabledSelected.layers) ? `
                                        ${stateDisabledSelected.layers.map(layer => createCSS(layer, `${stateDisabledSelected.container ? `${stateDisabledSelected.container.orientation == 1 ? 'column' : stateDisabledSelected.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([selected][disabled])', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateEnabledError ? `
                                    :host([error]) {
                                        ${parserCSS(stateEnabledError, '', 'dark')}
                                    }
                                    ${Array.isArray(stateEnabledError.layers) ? `
                                        ${stateEnabledError.layers.map(layer => createCSS(layer, `${stateEnabledError.container ? `${stateEnabledError.container.orientation == 1 ? 'column' : stateEnabledError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error])', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateHoveredError ? `
                                    @media (hover: hover) and (pointer: fine) {
                                        :host([error]:hover) {
                                            ${parserCSS(stateHoveredError, '', 'dark')}
                                        }
                                        ${Array.isArray(stateHoveredError.layers) ? `
                                            ${stateHoveredError.layers.map(layer => createCSS(layer, `${stateHoveredError.container ? `${stateHoveredError.container.orientation == 1 ? 'column' : stateHoveredError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:hover)', 'dark')).join('')}
                                        ` : ''}
                                    }
                                ` : ''}${statePressedError ? `
                                    :host([error]:active) {
                                        ${parserCSS(statePressedError, '', 'dark')}
                                    }
                                    ${Array.isArray(statePressedError.layers) ? `
                                        ${statePressedError.layers.map(layer => createCSS(layer, `${statePressedError.container ? `${statePressedError.container.orientation == 1 ? 'column' : statePressedError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:active)', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateFocusedError ? `
                                    :host([error]:focus), :host([error]:focus-within) {
                                        ${parserCSS(stateFocusedError, '', 'dark')}
                                    }
                                    ${Array.isArray(stateFocusedError.layers) ? `
                                        ${stateFocusedError.layers.map(layer => createCSS(layer, `${stateFocusedError.container ? `${stateFocusedError.container.orientation == 1 ? 'column' : stateFocusedError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:focus)', 'dark')).join('')}
                                    ` : ''}
                                    ${Array.isArray(stateFocusedError.layers) ? `
                                        ${stateFocusedError.layers.map(layer => createCSS(layer, `${stateFocusedError.container ? `${stateFocusedError.container.orientation == 1 ? 'column' : stateFocusedError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error]:focus-within)', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}${stateDisabledError ? `
                                    :host([error][disabled]), :host([error][disabled]:hover), :host([error][disabled]:active), :host([error][disabled]:focus), :host([error][disabled]:focus-within) {
                                        ${parserCSS(stateDisabledError, '', 'dark')}
                                        cursor: default;
                                    }
                                    ${Array.isArray(stateDisabledError.layers) ? `
                                        ${stateDisabledError.layers.map(layer => createCSS(layer, `${stateDisabledError.container ? `${stateDisabledError.container.orientation == 1 ? 'column' : stateDisabledError.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host([error][disabled])', 'dark')).join('')}
                                    ` : ''}
                                ` : ''}
                            }
                        ` : ''}
                    </style>
                    ${stateEnabled && Array.isArray(stateEnabled.layers) ?
                        props?.query ?
                            until(
                                getData(props.query, this.render, updated).then(data => {
                                    if (data && data.length > 0) {
                                        return data.map((item, index) => {
                                            if (props.query.infinite && (data.length >= props.query.limit)) {
                                                this.handleIntersection();
                                            }

                                            return stateEnabled.layers.map(layer => 
                                                createHTML(layer, 
                                                        JSON.stringify(item), 
                                                        this.overrides, 
                                                        `${props.query.collection}_${item.$id}`, 
                                                        props, 
                                                        this.render, 
                                                        updated
                                                ));
                                        });
                                    } else {
                                        return stateEnabled.layers.map(layer => createHTML(layer, '', this.overrides, 'empty_content', props));
                                    }
                                }),
                                html``
                            )
                        : 
                        props?.getAccount ? 
                            until(
                                getAccount(this.render, updated).then(data => {
                                    return stateEnabled.layers.map(layer => createHTML(layer, JSON.stringify(data), this.overrides, '', props));
                                }),
                                html``
                            )
                        :
                        props?.map ?
                            props.map.map(item => stateEnabled.layers.map(layer => createHTML(layer, JSON.stringify(parseIfString(item)), this.overrides, '', props, this.render, updated)))
                        :
                        stateEnabled.layers.map(layer => createHTML(layer, this.data ? JSON.stringify(this.data) : '', this.overrides, '', props, this.render, updated))
                    : ''}
                `;
                render(template, this.shadowRoot);
            }

            clickHandler = event => {
                if (this.clickCount > 0) return;

                let element = event.target;
                let data = {};
                let path = window.location.pathname;
                let slug = '';
                if (path.split('/')[2] && path.split('/')[2] !== 'view' && path.split('/')[2] !== 'edit') {
                    slug = path.split('/')[2];
                };
                let props = {};
                if (this.props) {
                    props = this.props;
                }

                // Ищем ближайший элемент, имеющий хотя бы один из нужных атрибутов
                while (element && !element.hasAttribute('setprops') &&
                    !element.hasAttribute('clickevent') &&
                    !element.hasAttribute('href')) {
                    element = element.parentElement;
                }

                if (!element) return;

                this.clickCount = +1;

                if (element.hasAttribute('data')) {
                    data = JSON.parse(decodeURIComponent(atob(element.getAttribute('data'))));
                }

                if (element.hasAttribute('setprops')) {
                    let setProps = JSON.parse(decodeURIComponent(atob(element.getAttribute('setprops'))));
                    Object.assign(props, setProps);
                    let updatedProps = JSON.stringify(props);
                    this.setAttribute('props', btoa(encodeURIComponent(updatedProps)));
                }

                if (element.hasAttribute('clickevent')) {
                    try {
                        const func = new Function('data', 'slug', 'props', `return ${element.getAttribute('clickevent')}`);
                        func(data, slug, props);
                    } catch (error) {
                        return null;
                        //return clickevent; // возвращаем исходное value, что может быть не желаемым поведением
                    }
                }

                if (element.hasAttribute('href')) {
                    const href = element.getAttribute('href');
                    const transition = element.getAttribute('transition');
                    const scroll_to = element.getAttribute('scrollto') || '';
                    if (href) {
                        changeURL(href, transition, scroll_to);
                    } else {
                        return;
                    }
                }
                setTimeout(() => {
                    this.clickCount = 0;
                }, 300);
            };

            handleInputChange() {
                if (componentName === 'input-text') {
                    this.setupInputText();
                }

                if (componentName === 'input-image') {
                    this.setupInputImage();
                }

                if (componentName === 'textarea') {
                    this.setupTextarea();
                }

                if (componentName === 'qr-scanner') {
                    this.setupQRScanner();
                }
            }

            setupInputText() {
                const input = this.shadowRoot.querySelector('.input');

                if (this.hasAttribute('disabled')) {
                    input.setAttribute('disabled', '');
                } else if (!this.hasAttribute('disabled') && input.hasAttribute('disabled')) {
                    input.removeAttribute('disabled');
                }

                const eventHandler = (event) => {
                    let props = {};
                    if (this.props) {
                        props = this.props;
                    }

                    const newValue = event.target.value;
                    if (props.value !== newValue) {
                        props.value = newValue;
                        props.error = null;
                        this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                        if (this.hasAttribute('error')) { this.removeAttribute('error'); }
                    }
                };

                input.removeEventListener('input', this.boundEventHandler);
                this.boundEventHandler = eventHandler.bind(this);
                input.addEventListener('input', this.boundEventHandler);

                const clickHandler = () => {
                    input.focus();
                };

                this.removeEventListener('click', this.boundClickHandler);
                this.boundClickHandler = clickHandler.bind(this);
                this.addEventListener('click', this.boundClickHandler);

                let props = {};
                if (this.props) {
                    props = this.props;
                }

                if (props.value == null) {
                    input.value = '';
                }

                if (props.type === 'phone') {
                    let phoneNumber = input.value;
                    if (phoneNumber) {
                        phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
                        phoneNumber = `+${phoneNumber.slice(0, 1).replace(/(8)/g, "7").replace(/(9)/g, "7 (9")}${(phoneNumber[1] ? ' (' : '')}${phoneNumber.slice(1, 4)}${(phoneNumber[4] ? ') ' : '')}${phoneNumber.slice(4, 7)}${(phoneNumber[7] ? '-' : '')}${phoneNumber.slice(7, 9)}${(phoneNumber[9] ? '-' : '')}${phoneNumber.slice(9, 11)}`;
                        input.value = phoneNumber;
                    }
                }

                if (document.documentElement.clientWidth < '1000' && navigator.maxTouchPoints > 0) {
                    let stickyEl = querySelectorAllDeep('.sticky');
                    const focusHandler = () => {
                        stickyEl.forEach(element => {
                            element.style.position = 'static';
                        });
                    };
            
                    input.removeEventListener('focus', this.boundFocusHandler);
                    this.boundFocusHandler = focusHandler.bind(this);
                    input.addEventListener('focus', this.boundFocusHandler);
            
                    const blurHandler = () => {
                        stickyEl.forEach(element => {
                            element.style.position = '';
                        });
                    };
            
                    input.removeEventListener('blur', this.boundBlurHandler);
                    this.boundBlurHandler = blurHandler.bind(this);
                    input.addEventListener('blur', this.boundBlurHandler);
                }
            }

            setupInputImage() {
                const input = this.shadowRoot.querySelector('.input-image');

                let props = {};
                if (this.props) {
                    props = this.props;
                }
                if (props?.image) {
                    let setProps = { 'value': { 'image': props.image, 'image_preload': props.preload, 'image_transparent': props.transparent } };
                    Object.assign(props, setProps);
                    let updatedProps = JSON.stringify(props);
                    this.setAttribute('props', btoa(encodeURIComponent(updatedProps)));
                }

                const changeHandler = async (event) => {

                    const file = event.target.files[0];
                    if (file) {
                        const reader = new FileReader();

                        reader.addEventListener("load", () => { // Используем стрелочную функцию здесь
                            const img = new Image();
                            img.src = reader.result;

                            img.onload = async () => {
                                const canvas = document.createElement('canvas');
                                canvas.width = img.naturalWidth;
                                canvas.height = img.naturalHeight;
                                const ctx = canvas.getContext('2d');
                                ctx.drawImage(img, 0, 0);

                                const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
                                const hasTransparency = pixelData.some((val, i) => (i + 1) % 4 === 0 && val !== 255);

                                let transparent = false;
                                if (hasTransparency) {
                                    transparent = true;
                                }

                                try {
                                    let result = await uploadImage(file);

                                    let setProps = { 'image': result.image, 'preload': result.preload, 'transparent': transparent, 'value': { 'image': result.image, 'image_preload': result.preload, 'image_transparent': transparent } };
                                    Object.assign(props, setProps);
                                    let updatedProps = JSON.stringify(props);
                                    // Используем this из контекста класса
                                    this.setAttribute('props', btoa(encodeURIComponent(updatedProps)));
                                } catch (e) {
                                    // handle error
                                    let setProps = { 'file': '' };
                                    Object.assign(props, setProps);
                                    let updatedProps = JSON.stringify(props);
                                    this.setAttribute('props', btoa(encodeURIComponent(updatedProps)));
                                }
                            }
                        }, false);

                        reader.readAsDataURL(file);
                    }
                };

                input.removeEventListener('change', this.boundChangeHandler);
                this.boundChangeHandler = changeHandler.bind(this);
                input.addEventListener('change', this.boundChangeHandler);
            }

            setupTextarea() {
                const textarea = this.shadowRoot.querySelector('.textarea');
            
                // Manage disabled state
                if (this.hasAttribute('disabled')) {
                    textarea.setAttribute('disabled', '');
                } else if (!this.hasAttribute('disabled') && textarea.hasAttribute('disabled')) {
                    textarea.removeAttribute('disabled');
                }
            
                // Handle input event
                const eventHandler = (event) => {
                    let props = {};
                    if (this.props) {
                        props = this.props;
                    }
            
                    const newValue = event.target.value;
                    if (props.value !== newValue) {
                        props.value = newValue;
                        props.error = null;
                        this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                        if (this.hasAttribute('error')) { this.removeAttribute('error'); }
                    }
                };
            
                textarea.removeEventListener('input', this.boundEventHandler);
                this.boundEventHandler = eventHandler.bind(this);
                textarea.addEventListener('input', this.boundEventHandler);
            
                // Handle click to focus
                const clickHandler = () => {
                    textarea.focus();
                };
            
                this.removeEventListener('click', this.boundClickHandler);
                this.boundClickHandler = clickHandler.bind(this);
                this.addEventListener('click', this.boundClickHandler);
            
                let props = {};
                if (this.props) {
                    props = this.props;
                }
            
                // Initialize textarea value
                if (props.value == null) {
                    textarea.value = '';
                }

                if (document.documentElement.clientWidth < '1000' && navigator.maxTouchPoints > 0) {
                    let stickyEl = querySelectorAllDeep('.sticky');
                    const focusHandler = () => {
                        stickyEl.forEach(element => {
                            element.style.position = 'static';
                        });
                    };
            
                    textarea.removeEventListener('focus', this.boundFocusHandler);
                    this.boundFocusHandler = focusHandler.bind(this);
                    textarea.addEventListener('focus', this.boundFocusHandler);
            
                    const blurHandler = () => {
                        stickyEl.forEach(element => {
                            element.style.position = '';
                        });
                    };
            
                    textarea.removeEventListener('blur', this.boundBlurHandler);
                    this.boundBlurHandler = blurHandler.bind(this);
                    textarea.addEventListener('blur', this.boundBlurHandler);
                }
            }

            setupQRScanner() {
                let props = {};
                if (this.props) {
                    props = this.props;
                }

                this.codeReader = new BrowserMultiFormatReader();

                this.videoElement = this.shadowRoot.getElementById('video');

                // Установите ограничения для доступа к камере (например, использование задней камеры на мобильных устройствах)
                const constraints = {
                    video: {
                        facingMode: 'environment'
                    }
                };
    
                // Пытаемся получить доступ к камере
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream) => {
                        this.videoElement.srcObject = stream;
    
                        // Добавьте обработчик события, чтобы видео началось после загрузки метаданных
                        this.videoElement.onloadedmetadata = () => {
                            this.videoElement.play();
                        };
    
                        this.codeReader.decodeFromVideoDevice(null, this.videoElement, (result, err) => {
                            if (result) {
                                const qrURL = new URL(result.getText());
                                const table = qrURL.searchParams.get("table");

                                if (table && this.qrScanning) {
                                    this.qrScanning = false;
                                    //sessionStorage.setItem('table', table);

                                    const currentDate = new Date();
                                    currentDate.setHours(currentDate.getHours() + config.tableLifetime);
                                    const tableDate = `{"table":"${table}","expires":"${currentDate.toISOString()}"}`;
                                    sessionStorage.setItem('table', tableDate);
                                    console.log(1);
                                    
                                    this.stopQRScanner();
                                    if (props?.fields) props.fields.table = table;
                                    props.state = 'submit';
                                    this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                                }
                            }
                            if (err && !(err.name === 'NotFoundException')) {
                                //console.error(err);
                            }
                        });
                    })
                    .catch((err) => {
                        console.error('Error accessing the camera:', err);
                    });
                    window.addEventListener('popstate', () => this.stopQRScanner(), { once: true });
            }
            stopQRScanner() {
                this.codeReader.reset();
                if (this.videoElement.srcObject) {
                    this.videoElement.srcObject.getTracks().forEach(track => track.stop());
                }
            }

            handleIntersection() {
                requestAnimationFrame(() => {
                    //const lastElement = this.shadowRoot.querySelector('[last]');
                    const elementCount = this.shadowRoot.childElementCount;
                    const lastElement = this.shadowRoot.lastElementChild;
                
                    if (lastElement && this.props.query.infinite) {
                        this.observer = new IntersectionObserver(entries => {
                            entries.forEach(entry => {
                                if (entry.isIntersecting) {
                                    // console.log('Element with last="true" is visible!');
                                    // Подгрузка новых данных
                                    let offset = elementCount - 1; // минут <style>
                
                                    if (this.props.query.limit && this.props.query.offset !== offset) {
                                        this.props.query.offset = offset;
                                        this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(this.props))));

                                        let date = new Date().toISOString();
                                        let url = window.location.origin;
                                        let animation = 'slidein';
                                        if (window.history?.state) {
                                            date = window.history.state?.date ? window.history.state.date : date;
                                            url = window.history.state?.url ? window.history.state.url : url;
                                            animation = window.history.state?.animation ? window.history.state.animation : animation;
                                        }
                                        history.replaceState({ date: date, url: url, animation: animation, offset: offset }, null, url);
                                        sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
                                    }
                                    this.observer.unobserve(entry.target); // Разрешено для остановки наблюдения
                                }
                            });
                        }, {
                            threshold: 0.5
                        });
                        this.observer.observe(lastElement);
                    }
                });
            }

            async handleFormSubmission(formID, form, success, error) {
                if (form) {
                    let props = {};
                    if (this.props) {
                        props = this.props;
                    }

                    postData(formID, form, props?.collection ? props.collection : '', props?.documentID ? props.documentID : '', props?.fields ? JSON.stringify(props.fields) : '', props?.permissions ? props.permissions : '', success, error).then(result => {
                        if (result.error) {
                            props.state = null;
                            this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                            //console.error(result.error);
                            //alert(result.error);
                        } else {
                            props.state = 'submited';
                            this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));

                            if (props.timer) {
                                const duration = props.timer; // Длительность таймера в секундах
                                let currentTime = duration;

                                const onTick = (currentTime) => {
                                    props.timer = currentTime;
                                    this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                                    //console.log(`Осталось времени: ${currentTime} секунд`);
                                };

                                const onComplete = () => {
                                    props.timer = null;
                                    props.state = null;
                                    this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                                    //console.log('Таймер завершён!');
                                };

                                const timerId = setInterval(() => {
                                    currentTime--;
                                    onTick(currentTime);

                                    if (currentTime <= 0) {
                                        clearInterval(timerId);
                                        onComplete();
                                    }
                                }, 1000);
                            }
                            //console.log(result.response);
                            //alert(successMessage);
                        }
                    }).catch(e => {
                        props.state = null;
                        this.setAttribute('props', btoa(encodeURIComponent(JSON.stringify(props))));
                        console.error('An unexpected error occurred:', e);
                    });
                }
            }

        }
        const nameComponent = 'component-' + componentName;

        if (!customElements.get(nameComponent)) {
            //console.log('Assembled: '+nameComponent);
            customElements.define(nameComponent, window[className]);
        }
    }
}