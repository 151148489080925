import { changeURL } from './changeURL.js';
export function responseHandleForm(data) {
    if (data && data.js) {
        try {
            (new Function(`return ${data.js}`))();
        } catch (error) {
            console.error(error);
        }
    }
    if (data && data.link) {
        const link = data.link;
        const transition = data.animation || 'default';
        const scroll_to = data.scrollto || '';
        if (link) {
            changeURL(link, transition, scroll_to);
        }
    }
}