import {config} from '../config.js';
import {toggleSpinner} from './toggleSpinner.js';
import {changeURL} from './changeURL.js';
import {deleteJWT} from './deleteJWT.js';
import { Client, Account, Teams, Databases } from 'appwrite';

// Инициализация клиента Appwrite
const endpoint = config.endpoint;
const project = config.project;
const database = config.database;

const client = new Client()
    .setEndpoint(endpoint)
    .setProject(project)
;
const databases = new Databases(client);

// Создание экземпляров Account и Teams для работы с учетными записями и командами
const account = new Account(client);
const teams = new Teams(client);

function setTimeStampQuery(input) {
    const encodedInput = btoa(JSON.stringify(input));
    const timeStamp = new Date().toISOString();
    sessionStorage.setItem(encodedInput, timeStamp);
}
function isTimeExpired(input) {
    const encodedInput = btoa(JSON.stringify(input));
    const storedTimeStamp = sessionStorage.getItem(encodedInput);

    if (!storedTimeStamp) {
        // Нет сохраненного значения
        return true;
    }

    // Переводим сохраненное время из ISO-строки в объект Date
    const storedDate = new Date(storedTimeStamp);

    const currentDate = new Date();

    const timeDifference = currentDate - storedDate;

    let valueTimeDifference = 60000; // 60 секунд (60000 миллисекунд)

    // Проверяем, больше ли разница чем valueTimeDifference
    return timeDifference > valueTimeDifference;
}

export async function getAccount(rerender, rerendered) {
    const storageKey = 'account';

    let response = {};
    // Проверка наличия данных в localStorage
    const cachedData = localStorage.getItem(storageKey);
    if (cachedData) {
        response = JSON.parse(cachedData);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const secret = urlParams.get('secret');
    const userId = urlParams.get('userId');
    const magicURL = urlParams.get('magicURL');
    const logout = urlParams.get('logout');
    const verified = urlParams.get('verified');
    if(magicURL && secret && userId && rerendered !== true) {
        createSessionMagicURL(response, secret, userId, storageKey, rerender, rerendered);
    } else if (verified) {
        window.snackbarManager.addItem({ id: 'verified', type: 'info', message: 'Email подтвержден', duration: 5000 });
        changeURL(window.location.pathname, 'keepScroll', '');
    } else if (logout) {
        logOut(response, storageKey, rerender, rerendered);
    } else {
        initializeUser(response, storageKey, rerender, rerendered);
    }

    /* if (!secret && !userId && navigator.onLine && rerendered == false && isTimeExpired(storageKey)) {
        initializeUser(response, storageKey, rerender, rerendered);
    } */
    
    return response;
}
async function createSessionMagicURL(response, secret, userId, storageKey, rerender, rerendered) {
    toggleSpinner(true);

    try {
        sessionStorage.removeItem(btoa(JSON.stringify(storageKey)));
        await account.deleteSession('current');
    } catch (e) {}

    try {
        const user = await account.createSession(userId, secret);
        if (user) {
            const userAccount = await account.get();
            const userTeams = await teams.list();
            // Определение роли пользователя
            let role = 'user';
            if (userTeams.teams.some(team => team.name === 'admin')) {
                role = 'admin';
            } else if (userTeams.teams.some(team => team.name === 'managers')) {
                role = 'manager';
            }
            // Добавление роли в userAccount
            userAccount.role = role;

            window.snackbarManager.removeItem({ id: 'sendMagicURL' });
            setTimeStampQuery(storageKey);
            if (response.$id !== userAccount.$id) {
                // Сохранение данных в localStorage
                localStorage.setItem(storageKey, JSON.stringify(userAccount));
                //rerender?.(true);
                changeURL(window.location.pathname, 'keepScroll', '');
            }
        }
        toggleSpinner(false);
        //console.log(user);
    } catch (e) {
        toggleSpinner(false);
        if (e.code === 429) {
            //console.error('Too Many Requests: ', e.message);
            window.snackbarManager.addItem({ type: 'error', message: 'У вас слишком много запросов. Пожалуйста, подождите минуту и попробуйте снова.', duration: 0 });
        }
        //console.error(e);
    }
}
async function initializeUser(response, storageKey, rerender, rerendered) {
    if (!response.$id && navigator.onLine) {
        toggleSpinner(true);

        /* try {
            sessionStorage.removeItem(btoa(JSON.stringify(storageKey)));
            await account.deleteSession('current');
        } catch (e) {} */

        try {
            const userAccount =  await account.createAnonymousSession();
            let role = 'guest';

            if (userAccount) {
                userAccount.role = role;
                setTimeStampQuery(storageKey);
    
                localStorage.setItem(storageKey, JSON.stringify(userAccount));

                rerender?.(true);
            }
        } catch (e) {
            console.error(e);
        }

        toggleSpinner(false);
    } else if (response.$id && navigator.onLine && rerendered !== true && isTimeExpired(storageKey)) {
        try {
            // Получение профиля пользователя
            const userAccount = await account.get();
    
            // Получение списка команд пользователя
            const userTeams = await teams.list();
    
            // Определение роли пользователя
            let role = 'guest';
    
            // Проверка наличия email
            if (userAccount.email) {
                role = 'user';
            }
    
            // Проверка наличия команд и ролей
            if (userTeams.teams && userTeams.teams.length > 0) {
                const roles = new Set(userTeams.teams.map(team => team.name));
                
                if (roles.has('admin')) {
                    role = 'admin';
                } else if (roles.has('managers')) {
                    role = 'manager';
                }
            }
    
            // Добавление роли в userAccount
            userAccount.role = role;
    
            setTimeStampQuery(storageKey);
            if (response.$id !== userAccount.$id || response.$updatedAt !== userAccount.$updatedAt || response.role !== userAccount.role) {
                // Сохранение данных в localStorage
                localStorage.setItem(storageKey, JSON.stringify(userAccount));
                rerender?.(true);
            }
    
            /* if (userAccount && userTeams) {
                toggleSpinner(false);
            } */
        } catch (e) {
            if (e.code === 401) {
                localStorage.removeItem('cookieFallback');
                localStorage.removeItem(storageKey);
                sessionStorage.removeItem(btoa(JSON.stringify(storageKey)));
                deleteJWT();
                //initializeUser(response, storageKey, rerender, rerendered);
                getAccount(rerender, rerendered);
            }
        }
    }

    
}
async function logOut(response, storageKey, rerender, rerendered) {
    if (!response.$id) {
        toggleSpinner(true);
    }
    try {
        await account.deleteSession('current');
        /* const currentSession = await account.getSession(
            'current' // sessionId
        );

        if (currentSession) {
            await account.deleteSession(
                currentSession.$id // sessionId
            );
            localStorage.removeItem(storageKey);
            sessionStorage.removeItem(btoa(JSON.stringify(storageKey)));
            deleteJWT();
            //rerender?.(true);
            changeURL(window.location.pathname, 'keepScroll', '');
            toggleSpinner(false);
        } */
    } catch (e) {}

    localStorage.removeItem('cookieFallback');
    localStorage.removeItem(storageKey);
    sessionStorage.removeItem(btoa(JSON.stringify(storageKey)));
    deleteJWT();
    //rerender?.(true);
    changeURL(window.location.pathname, 'keepScroll', '');
    
    toggleSpinner(false);
}