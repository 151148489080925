/* export function routing(page) {
    history.pushState(page, null, '/'+page+'/');
    document.querySelector('app-layout').shadowRoot.querySelector('app-page').setAttribute('page', page);
} */
import {setPageScroll} from './setPageScroll.js';
export function routing() {
    let path = window.location.pathname;
    if (!path || path === '/index.html') {
        path = '/home/view';
    }
    //document.querySelector('app-page').setAttribute('value', path);
    document.getElementById('app').setAttribute('value', path);
    setPageScroll(path);
}
/* export function routing() {
    let path = window.location.pathname;
    if (!path) {
        path = '/home';
    }
    
    const appElement = document.getElementById('app');
    if (appElement.getAttribute('value') !== path) {
        appElement.setAttribute('value', path);
    }
} */
/* export function routing() {
    let url = {page: 'home', navtab: 'goods'};
    let query = document.location.search.substr(1);
    if (query != '') {
        url = query.split('&').reduce(function (res, item) {
            let parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});
    };
    console.log(url);
    document.getElementById('app').setAttribute('value', btoa(encodeURIComponent(JSON.stringify(url))));   
} */